import Vue from "vue";
import App from "./App.vue";

// Vuesax Component Framework
import Vuesax from "vuesax";


///////////////////Google Social Login /////////////////////////
import GoogleAuth from '@/config/google_oAuth.js'
const gauthOption = {
  clientId: 
  '707231563844-e5cpkqrlt62gncmj6b84of5sml9lp8g9.apps.googleusercontent.com',
 // '140759690297-8266gprptftfmb0q9tka9b75dphuc24b.apps.googleusercontent.com',
  scope: 'profile email',
  prompt: 'select_account'
}
Vue.use(GoogleAuth, gauthOption)
Vue.config.productionTip = false
///////////////////End Google Social Login /////////////////////////

import "material-icons/iconfont/material-icons.css"; //Material Icons
import "vuesax/dist/vuesax.css"; // Vuesax
import numeral from "numeral";
import numFormat from "vue-filter-number-format";
Vue.use(Vuesax);
Vue.component('video-slide', {
  template: '#video-template',
  props: ['src']
})
import vue3GoogleLogin from 'vue3-google-login'
Vue.use(vue3GoogleLogin, {
  clientId: '533501946906-ips59b3crr2090dev8ckhln491fct5us.apps.googleusercontent.com'
})

import VueVideoPlayer from 'vue-video-player'
import 'video.js/dist/video-js.css'
Vue.use(VueVideoPlayer, /* {
  options: global default options,
  events: global videojs events
} */)
import VCalendar from "v-calendar";
Vue.use(VCalendar);
import Calendar from 'v-calendar';
Vue.component("calendar", Calendar);
import sideBar from "@/components/SideBar.vue";
Vue.component("sideBar", sideBar);

import CustomButton from "@/components/CustomButton.vue";
Vue.component("CustomButton", CustomButton);

Vue.filter("numFormat", numFormat(numeral));

import moment from "moment";



Vue.filter("formatDate", function(value) {
  if (value) {
    return moment(String(value)).format("DD/MM/YYYY");
  }
});
Vue.filter("formatDay", function(value) {
  if (value) {
    return moment(String(value)).format("DD/MM/YYYY");
  }
});



import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";





import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

Vue.component('vue-phone-number-input', VuePhoneNumberInput);
// axios
import axios from "./axios.js";
Vue.prototype.$http = axios;

// API Calls
import "./http/requests";

// Theme Configurations
import "../themeConfig.js";

// autocomplete html
// import DisableAutocomplete from "vue-disable-autocomplete";


// ACL
import acl from "./acl/acl";

// Globally Registered Components
import "./globalComponents.js";

// Styles: SCSS
import "./assets/scss/main.scss";

// Tailwind
import "@/assets/css/main.css";

// Vue Router
import router from "./router";

// Vuex Store
import store from "./store/store";

// i18n
import i18n from "./i18n/i18n";



import vmodal from "vue-js-modal";
Vue.use(vmodal);

// VeeValidate
/*eslint-disable */
import VeeValidate from "vee-validate";

import ARvalidationMessages from "vee-validate/dist/locale/ar";
import ENvalidationMessages from "vee-validate/dist/locale/en";
import FRvalidationMessages from "vee-validate/dist/locale/fr";

Vue.use(VeeValidate, {
  // i18nRootKey: "validations", // customize the root path for validation messages.
  i18n,
  dictionary: {
    ar: ARvalidationMessages,
    en: ENvalidationMessages,
    fr: FRvalidationMessages
  }
});
// switch (locale) {
//   case "ar":
//     Validator.localize("ar", ar);

//     break;
//   case "en":
//     Validator.localize("en", en);

//     break;
//   case "fr":
//     Validator.localize("fr", fr);

//     break;
//   default:
//     Validator.localize("en", en);
//     break;
// }

VeeValidate.Validator.extend("lowCase", {
  getMessage: field => `The password must contain at least: 1 lowercase letter`,
  validate: value => {
    var strongRegex = new RegExp("^(?=.*[a-z])");
    return strongRegex.test(value);
  }
});
VeeValidate.Validator.extend("UpCase", {
  getMessage: field => `The password must contain at least: 1 uppercase letter`,
  validate: value => {
    var strongRegex = new RegExp("^(?=.*[A-Z])");
    return strongRegex.test(value);
  }
});

VeeValidate.Validator.extend("number", {
  getMessage: field => `The password must contain at least: 1 number`,
  validate: value => {
    var strongRegex = new RegExp("^(?=.*[0-9])");
    return strongRegex.test(value);
  }
});
VeeValidate.Validator.extend("SpecialChar", {
  getMessage: field =>
    `The password must contain at least: 1 special character`,
  validate: value => {
    var strongRegex = new RegExp("^(?=.*[!@#$%^&*])");
    return strongRegex.test(value);
  }
});
/* eslint-enable*/

//Vue-Select
import vSelect from "vue-select";
Vue.component("v-select", vSelect);

import ECharts from "vue-echarts";
import "echarts/lib/chart/bar";
import "echarts/lib/component/tooltip";
import "echarts-gl";
import rate from "@/components/rate.vue"
import popLogo from "@/components/Pop-logo.vue"
// register component to use
Vue.component("v-chart", ECharts);
Vue.component("popLogo",popLogo);
Vue.component("rate",rate);
// Vuejs - Vue wrapper for hammerjs
import { VueHammer } from "vue2-hammer";
Vue.use(VueHammer);


// PrismJS
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";

// Feather font icon
require("./assets/css/iconfont.css");




new Vue({

  components: {

      Calendar,

  }
})

// Vue.use(VueScrollTo, {
//   container: "body",
//   duration: 3000,
//   easing: "ease",
//   offset: 0,
//   force: true,
//   cancelable: true,
//   onStart: false,
//   onDone: false,
//   onCancel: false,
//   x: false,
//   y: true
// });
// Vue select css
// Note: In latest version you have to add it separately
// import 'vue-select/dist/vue-select.css';
debugger
Vue.config.productionTip = false;


import  firebase from "firebase";

var firebaseConfig = {
  apiKey: "AIzaSyBd1uuuC-ziNXimrdQRS1QveZLlPqIL4Xo",
  authDomain: "doctor4me-f7511.firebaseapp.com",
  projectId: "doctor4me-f7511",
  storageBucket: "doctor4me-f7511.appspot.com",
  messagingSenderId: "281690047702",
  appId: "1:281690047702:web:622de433564f9eeba67577",
  measurementId: "G-RL2N073ELC"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
import { mainSetting } from '@/gloabelConstant.js'

if(firebase.messaging.isSupported())
{

const messaging = firebase.messaging();
messaging
    .requestPermission()
    .then(() => firebase.messaging().getToken())
    .then((token) => {
        console.log(token) // Receiver Token to use in the notification
    })
    .catch(function(err) {
        console.log("Unable to get permission to notify.", err);
    });
messaging.usePublicVapidKey("BD5ZT3sr6Gn6UZIZNvRwFn0B5TBdtO9FbiSYZDu0EtopKQ7wBPgycZ17Sp5ZjsFKnktIrzY-QwuJA-SWkoGBR9w");

messaging.onMessage((payload) => {
    debugger
    console.log('Message received. ', payload);
});



messaging.getToken().then((currentToken) => {
  debugger
      if (currentToken) {

        mainSetting.FCMToken=currentToken;
        console.log(mainSetting.FCMToken);

      } else {

        console.log('No Instance ID token available. Request permission to generate one.');

      }
    }).catch((err) => {
      console.log('An error occurred while retrieving token. ', err);

    });
  }

new Vue({
  router,
  store,
  i18n,
  acl,
  render: h => h(App)
}).$mount("#app");


