import axios from "../../../axios/index.js";
import store from "../../../../store/store.js";
import { mainSetting } from '@/gloabelConstant.js';
//import EncryptionHandler from "@/http/requests/auth/jwt/EncryptionHandler.js";

// Token Refresh
let isAlreadyFetchingAccessToken = false;
let subscribers = [];

function onAccessTokenFetched(access_token) {
  subscribers = subscribers.filter(callback => callback(access_token));
}

function addSubscriber(callback) {
  subscribers.push(callback);
}

export default {
  init(vm) {
    store.commit("auth/SET_BEARER", localStorage.getItem("accessToken"));
debugger
   axios.interceptors.request.use(
     // function(successfulReq) {
      
    //   if(successfulReq.url!="/GetUserNotification"&&successfulReq.url!="api/Doctor/SearchDoctors"&&successfulReq.url!="/API/Hospital/SearchHospitals")
    //      vm.$vs.loading();
    //   return successfulReq;

    // },
    
      config => {
        // Do something before request is sent
        if (
          config.method != "get" &&
          config.headers["Content-Type"] != "multipart/form-data"
        ) {
          //config.data = EncryptionHandler.encrypt(config.data);
        }

        return config;
      },
      // function(error) {
      //   // Do something with request error
      //   return Promise.reject(error);
      // }
      function(error) {
      
        vm.$vs.loading.close();
        return Promise.reject(error);
      }
    );
    axios.interceptors.response.use(
      response => {
        if (response.status === 200 || response.status === 201) {
          debugger;
          if(response.data != undefined && response.data != ""){
           // response.data = EncryptionHandler.decrypt(response.data);            
          }
          debugger;
          //return Promise.resolve(response);
          vm.$vs.loading.close();
          return response;
        } else {
          return Promise.reject(response);
        }
      },
      error => {
        if (error.response.status) {
        //  error.response.data = EncryptionHandler.decrypt(error.response.data);
          switch (error.response.status) {
            case 415:
              console.log("");
              break;
            case 401:
              // store.state.SessionExpired = true;
              // store.dispatch("auth/logout");
              //console.log("");
              const { config, response } = error;
              const originalRequest = config;
              if (!isAlreadyFetchingAccessToken) {
                isAlreadyFetchingAccessToken = true;
                store.dispatch("auth/fetchAccessToken").then(access_token => {
                  isAlreadyFetchingAccessToken = false;
                  onAccessTokenFetched(access_token);
                });
              }
    
              const retryOriginalRequest = new Promise(resolve => {
                addSubscriber(access_token => {
                  originalRequest.headers.Authorization = "Bearer " + access_token;
                  resolve(axios(originalRequest));
                });
              });
              return retryOriginalRequest;
              break;
            case 403:
              console.log("");
              break;
            case 404:
              console.log("");
              break;
            case 502:
              console.log("");
          }
          return Promise.reject(error);
        }
      }
    );
  },
  login(email, pwd) {
    return axios.post("/login", {
      email: email,
      password: pwd,
      fcmToken:mainSetting.FCMToken
    });
  },
  ChangeUserLanguage(languageId) {
    return axios.get("/Account/ChangeUserLanguage?languageID=" + languageId);
  },

  registerUser(confirmPassword, email, pwd, nameEN,userType,IsConfirmed,UserName,IsActive) {
    debugger
    return axios.post("/Registration", {
      confirmPassword: confirmPassword,
      email: email,
      password: pwd,
      nameEN: nameEN,
      userType:userType,
      IsConfirmed:IsConfirmed,
      IsActive:IsActive,
      UserName:UserName
    });
  }
  // refreshToken() {
  //   return axios.post("/refresh", {refreshToken: localStorage.getItem("refreshToken")})
  // }
};
