<template>
  <div class="app-img" id="customButton">
    <vs-button
    id="texthover"
     :color="Color"
     :type="Type"
     style="width:100%"
     @click="$emit('click')"><h1 id="" style="color:white">{{ $t(title) }}</h1></vs-button> 
  </div>
</template>

<script>
export default {
  data() {
    return {

    };
  },
  props: {
    title: String,
    Color:String,
    Type:String,
  },
    
  created() {
  },
}
</script>

<style>
   #customButton button
   {
         border: 3px solid rgb(255, 255, 255);
   }
  
 #texthover:hover h1 {
  color:#00c425 !important; 
  /* background-color: #00c425; */
}
</style>