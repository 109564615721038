<template>
    <div>
          <img src="@/assets/images/logo.png" :class="[windowWidth<=500? 'hidden':'']" style="width: 100px" alt="" />
    </div>
</template>
<script>
export default {
   computed:{
   windowWidth() {
      return this.$store.state.windowWidth;
    },
},
}
</script>