/*=========================================================================================
  File Name: moduleSpecialtyActions.js
  Description: Specialty Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js";

export default {
  AddSpecialty({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/Specialty/AddSpecialty", item)
        .then(response => {
          commit(
            "ADD_ITEM",
            Object.assign(item, { ID: response.data.Data.ID })
          );
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  GetAllSpecialties({ commit }) {
    return new Promise((resolve, reject) => {
      debugger;
      axios
        .get("api/Specialty/GetAllSpecialties")
        .then(response => {
          commit("SET_Specialty", response.data.Data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  SearchSpecialties({ commit },data) {
    return new Promise((resolve, reject) => {
      debugger;
      axios
        .post("api/Specialty/SearchSpecialties",data)
        .then(response => {
          commit("SET_Specialty", response.data.Data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  GetSpecialtyByParentSpecialtyID({ commit }, specialtyID) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          "api/Specialty/GetSpecialtyByParentSpecialtyID?ParentSpecialtyID=" +
            specialtyID
        )
        .then(response => {
          commit("SET_ChildSpecialty", response.data.Data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  // GetParentSpecialty({ commit }) {
  //   return new Promise((resolve, reject) => {
  //     axios
  //       .get("api/Specialty/GetParentSpecialty")
  //       .then(response => {
  //         commit("SET_ParentSpecialty", response.data.Data);
  //         resolve(response);
  //       })
  //       .catch(error => {
  //         reject(error);
  //       });
  //   });
  // },
  GetParentSpecialty({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/Specialty/GetParentSpecialty")
        .then(response => {
          commit("SET_ParentSpecialty", response.data.Data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  UpdateSpecialty({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/Specialty/UpdateSpecialty", item)
        .then(response => {
          commit("UPDATE_Specialty", item);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  GetSpecialty({ commit }, itemid) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/Specialty/GetSpecialty", itemid)
        .then(response => {
          commit("UPDATE_Specialty", response.data.Data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  DeleteSpecialty({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios
        .delete("api/Specialty/DeleteSpecialty?ID=" + item.ID)
        .then(response => {
          commit("REMOVE_ITEM", item.ID);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};
