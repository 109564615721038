//export const domain = "http://15.184.133.10/";
   //export const domain = 'https://api.doclinia.com/'
  // export const domain = 'http://109.205.181.0:5555/'
  // export const domain = "http://195.201.197.126:30/";
 // export const domain = process.env.VUE_APP_Base_Url
  export const domain ="https://admin.doclinia.com/"
 // export const domain = "http://localhost:58870/"

export const global = {
  vm: ""
};
export const mainSetting = {
  FCMToken:"",
  UploadRequestHeader: {
    Authorization: "Bearer " + localStorage.getItem("accessToken")
  }
};

export const toolbar = [
  ["bold", "italic", "underline", "link", "strike"], // toggled buttons
  ["blockquote", "code-block"],

  [{ header: 1 }, { header: 2 }], // custom button values
  [{ list: "ordered" }, { list: "bullet" }],
  [{ script: "sub" }, { script: "super" }], // superscript/subscript
  [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
  [{ direction: "rtl" }], // text direction

  [{ size: ["small", false, "large", "huge"] }], // custom dropdown
  [{ header: [1, 2, 3, 4, 5, 6, false] }],

  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  [{ font: [] }],
  [{ align: [] }],
  ["clean"], // remove formatting button
  ["image"]
];
