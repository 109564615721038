<template>
      <star-rating
             
              :rtl="$vs.rtl"
              :star-size="25"
              v-model="rateValue"
              :border-width="2"
              :disable="true"
              border-color="#d8d8d8"
                  active-color="#00c226"
              :rounded-corners="false"
              :star-points="[
                23, 2, 14, 17, 0, 19, 10, 34, 7, 50, 23, 43, 38, 50, 36, 34, 46,
                19, 31, 17,
              ]"
              :read-only="true"
              :show-rating="false"
            ></star-rating>
</template>
<script>

import StarRating from "vue-star-rating";
export default {
    components:{
        StarRating
    },
    props:{
        rateValue:{
            type: [Number],
            required: true
        }
    }
}
</script>