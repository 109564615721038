/*=========================================================================================
  File Name: moduleAuthActions.js
  Description: Auth Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import jwt from "../../http/requests/auth/jwt/index.js"
import { mainSetting } from '@/gloabelConstant.js'
import axios from "@/axios.js"
// import firebase from 'firebase/app'
// import 'firebase/auth'
import router from '@/router'

export default {
    SocialLogin({ commit }, model) {
        return new Promise((resolve, reject) => {
            debugger;
            axios
                .post("/SocialLogin", model)
                .then((response) => {
                    // Set accessToken
                    localStorage.setItem("expiresIn", response.data.Data.expiresIn)
                    localStorage.setItem("accessToken", response.data.Data.accessToken)
                    localStorage.setItem("refreshToken", response.data.Data.refreshToken)


                    // Update user details
                    commit('UPDATE_USER_INFO', response.data.Data.userData, { root: true })

                    // Set bearer token in axios
                    commit("SET_BEARER", response.data.Data.accessToken)

                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
     CreateMeetingSession(context, reservationId) {
        debugger;
        return new Promise((resolve, reject) => {
          axios
            .get("API/PatientReservationSession/CreateMeetingSession?reservationId="+reservationId)
            .then(response => {
              if (response.status == 200) {
                resolve(response);
              }
            })
            .catch(error => {
              reject(error);
            });
        });
      },
    ChangePassword(context, payload) {
        debugger
        return new Promise((resolve, reject) => {
            axios.post("/ChangePassword", payload)
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    SendMailForContactUs(context, data) {
        debugger
        return new Promise((resolve, reject) => {
            axios.post("/SendMailForContactUs", data)
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    // JWT
    loginJWT({ commit }, payload) {
        debugger;
        return new Promise((resolve, reject) => {
            debugger;
            jwt.login(payload.userDetails.email, payload.userDetails.password)
                .then(response => {

                    // If there's user data in response
                    if (response.status == 200) {
                        debugger;
                        // Navigate User to homepage

                        //window.location="/"

                        // Set accessToken
                        localStorage.setItem("expiresIn", response.data.Data.expiresIn)
                        localStorage.setItem("accessToken", response.data.Data.accessToken)
                        localStorage.setItem("refreshToken", response.data.Data.refreshToken)


                        // Update user details
                        commit('UPDATE_USER_INFO', response.data.Data.userData, { root: true })

                        // Set bearer token in axios
                        commit("SET_BEARER", response.data.Data.accessToken)
                            //router.push(router.currentRoute.query.to || '/')
                        resolve(response)
                    } else {
                        debugger;
                        reject({ message: "Wrong UserNane or Password" })
                    }

                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    registerUserJWT({ commit }, payload) {
        debugger;
        const { email, password, confirmPassword, nameEN, userType, IsConfirmed, UserName, IsActive } = payload.userDetails

        return new Promise((resolve, reject) => {

            // Check confirm password
            if (password !== confirmPassword) {
                reject({ message: "Password doesn't match. Please try again." })
            }

            jwt.registerUser(confirmPassword, email, password, nameEN, userType, IsConfirmed, UserName, IsActive)
                .then(response => {
                    // Redirect User

                    // Update data in localStorage
                    // localStorage.setItem("accessToken", response.data.accessToken)
                    // localStorage.setItem("refreshToken", response.data.refreshToken)

                    // commit('UPDATE_USER_INFO', response.data.userData, {root: true})

                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                    console.log(error)
                });
        })
    },
    fetchAccessToken() {
        return new Promise((resolve) => {
            jwt.refreshToken().then(response => { resolve(response) })
                .catch(function() {
                    debugger
                    router.push('/pages/login')
                })
        })
    },
    logout({ commit }) {

        // Set accessToken
        localStorage.removeItem("accessToken")
        localStorage.removeItem("refreshToken")
        localStorage.removeItem("expiresIn")
            // Update user details
        commit('CLEAR_USER_INFO', null, { root: true })

        // Set bearer token in axios
        commit("REMOVE_BEARER")

        axios.get("/logout?FCMToken=" + mainSetting.FCMToken)
    },
    ChangeUserLanguage(context, languageId) {
        debugger
        return axios.get("/Account/ChangeUserLanguage?languageID=" + languageId);
    },
    ChangePatientSystemLangauge(context, languageId) {
        debugger;
        var obj = {};
        obj.SystemLanguage = languageId;

        debugger
        return axios.post("/API/Patient/ChangePatientSystemLangauge", obj);
    },
}
